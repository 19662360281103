import { Route, Routes } from 'react-router-dom';

import Shenhe from './pages/img/shenhe';
import Label from './pages/img/label';
import FMU from './pages/img/fmu'
import Enhance from './pages/img/enhance';

import Error from './error';
import Redirect from './direct';
import StableDiffusion from './pages/art/sd'

export function Img() {
    return (
        <Routes>
            <Route path="/shenhe" element={<Shenhe />} />
            <Route path="/label" element={<Label />} />
            <Route path="/fmu" element={<FMU />} />
            <Route path="/enhance" element={<Enhance />} />
            <Route path="*" element={<Error />} />
        </Routes>
    );
}

export function Art() {
    return (
        <Routes>
            <Route path="/sd" element={<StableDiffusion errTitle="该功能暂未开放" />} />
            <Route path="*" element={<Error />} />
        </Routes>
    );
}