import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import { uuid } from './plug';
import Aegis from 'aegis-web-sdk';
import {
  Watermark, Avatar,
  Input, Typography, Row, Col, Divider, Space,
  Dropdown, Button
}
  from 'antd';
import { GithubOutlined, GlobalOutlined, UserOutlined } from '@ant-design/icons';
import menu from './Menu'
import Home from './Home';
import Redirect from './direct';
import Error from './error';
import { ProLayout, PageContainer } from '@ant-design/pro-components';
import { Art, Img } from './routes';
import cookie from 'react-cookies';
import BuyPage from './pages/buy';
import { ArCIAM, handleLogin, handleLogout, parseIdToken } from './login';
import md5 from 'md5';

const { Paragraph, Title } = Typography;

function Main() {
  global.setTitle = function (name) {
    document.title = `${name} - ArSrNa AI中心`
  }
  const navigate = useNavigate();
  const Location = useLocation();
  const [pathname, setPathname] = useState('/');
  const login = !(cookie.load('CIAM') === void '💿');

  useEffect(() => {
    ArCIAM.handleGetToken();
  }, []);


  return (
    <>
      <Watermark content={'Ar-Sr-Na 测试版本' + (login ? parseIdToken().payload.sub : '')}>
        <ProLayout
          title="ArSrNa AI中心"
          logo={require('./icon.ico')}
          layout="top"
          fixedHeader={true}
          menu={{
            type: "group"
          }}
          disableMobile
          location={{
            pathname
          }}
          menuItemRender={(item, dom) => (
            <div
              onClick={() => {
                navigate(item.path);
                setPathname(item.path);
              }}
            >
              {dom}
            </div>
          )}
          {...menu}
          actionsRender={() => (
            <div style={{ paddingRight: 10 }}>
              {login ? <>
                <Dropdown menu={{
                  items: [{
                    key: 'manageUser',
                    label: (<a target="_blank" href="https://user.arsrna.cn/">更改信息</a>),
                  }, {
                    key: 'logout',
                    label: (<a target="_blank" onClick={handleLogout}>退出登录</a>),
                  }]
                }}
                  arrow
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Avatar src={`https://cravatar.cn/avatar/${md5(parseIdToken().payload.email)}`} style={{ marginRight: 8 }} />
                    {parseIdToken().payload.nickname}</a>
                </Dropdown>
              </> :
                <Button onClick={handleLogin}><UserOutlined /> 请登录</Button>
              }
            </div>)}
        >
          <div style={{ minHeight: '60vh' }}>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/buy' element={<BuyPage />} />
              <Route path='/img/*' element={<Img />} />
              <Route path='/art/*' element={<Art />} />

              <Route path="*" element={<Error />} />
              <Route path="/redirect" element={<Redirect />} />
            </Routes>
          </div>
          {/* <PageContainer /> */}
          <FooterContent />
        </ProLayout>
      </Watermark >
    </>
  )
}

function FooterContent() {
  const span = { xs: 24, sm: 12, md: 6 };
  const aStyle = { display: 'flex', alignItems: 'center' };
  return (
    <>
      <Divider />
      <Row gutter={8}>

        <Col {...span}>
          <Space direction='vertical'>
            <Title level={4}>开发</Title>
            <a target="_blank" href='https://www.arsrna.cn/' rel='noreferrer'><GlobalOutlined /> Ar-Sr-Na 网站</a>
            <a target="_blank" href='https://www.arsrna.cn/app/' rel='noreferrer'><GlobalOutlined /> 资源与应用中心</a>
            <a target="_blank" href='https://github.com/ArSrNa/' rel='noreferrer'><GithubOutlined /> github</a>
            <a target="_blank" href='https://www.arsrna.cn/communication/' rel='noreferrer'><GlobalOutlined /> 联系我们</a>
          </Space>
        </Col>

        <Col {...span}>
          <Space direction='vertical'>
            <Title level={4}>关于</Title>
            <a target="_blank" href='https://www.arsrna.cn/about/' rel='noreferrer'>关于</a>
            <a target="_blank" href='https://www.arsrna.cn/policy/' rel='noreferrer'>用户协议</a>
            <a target="_blank" href='https://cloud.tencent.com/document/product/301/1967' rel='noreferrer'>腾讯云产品使用协议</a>
          </Space>
        </Col>

        <Col {...span}>
          <Space direction='vertical'>
            <Title level={4}>更多</Title>
            <a target="_blank" href='https://www.arsrna.cn/app/esrgan/' rel='noreferrer'>ESRGANUI 超分辨率</a>
            <a target="_blank" href='https://www.arsrna.cn/app/catch/' rel='noreferrer'>异步采集</a>
            <a target="_blank" href='https://www.arsrna.cn/app/render/' rel='noreferrer'>集群渲染</a>
            <a target="_blank" href='https://live.arsrna.cn/' rel='noreferrer'>音视频</a>
          </Space>
        </Col>

        <Col {...span}>
          <Space direction='vertical'>
            <Title level={4}>合规信息</Title>
            <a style={aStyle} target="_blank" href='http://qgzpdj.ccopyright.com.cn/registrationPublicity.html'>
              <img src="https://st1-res.arsrna.cn/logo.ico" style={{ marginRight: 5, width: 20 }} />
              鲁作登字-2022-F-00782000
            </a>
            <a style={aStyle} target='_blank' href='https://beian.miit.gov.cn/'>
              <img src={require('./images/beian.png')} style={{ marginRight: 5, width: 20 }} />
              琼ICP备2023005694号-1
            </a>
            {/* <a style={aStyle} target='_blank' href='https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=92420106MACYC0CW3H-SAIC_SHOW_10000092420106MACYC0CW3H1694430247768&signData=MEUCIQDeZmfEnoVdrk+Qk2C0f8hFAktsTgRbzDG4a21RUS+WtQIgKmK38ZJOYw1KTx2KG77/AabTqJlBHQyQh956bLOGl4U='>
              <img src={require('./images/lz4.png')} style={{ marginRight: 5, width: 20 }} />
              电子营业执照验证
            </a> */}
          </Space>
        </Col>
      </Row>

      <h3 style={{ textAlign: 'center' }}>Ar-Sr-Na 版权所有，禁止镜像或复制本站任何内容！</h3>
    </>
  )
}


function App() {
  const aegis = new Aegis({
    id: 'QlWyV8Np04vdN15Yok', // 上报 id
    uin: `ArAI ${uuid()}`, // 用户唯一 ID（可选）
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: true, // 静态资源测速
    spa: true, // spa 应用页面跳转的时候开启 pv 计算
    hostUrl: 'https://rumt-zh.com'
  });
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default App;
