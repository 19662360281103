import {
    Card, Col, Breadcrumb, Row, Form, Image as AImage,
    Typography, Alert, Space, message,
    Button, Spin, Modal, Progress, Upload
} from "antd";
import { useEffect, useState } from "react";
import { COSInit, cosConfig, fileUploadKey } from '../../cos';
import {
    InboxOutlined, DownloadOutlined, SwapOutlined,
    RotateLeftOutlined, RotateRightOutlined, ZoomOutOutlined,
    ZoomInOutlined
} from "@ant-design/icons"
import { handleLogin, token } from '../../login';
const { Dragger } = Upload;
const { Paragraph } = Typography;

export default function Enhance() {
    const cos = COSInit(token)
    const [input, setInput] = useState(null);
    const [result, setResult] = useState(null);
    const [progressData, setProgressData] = useState({});
    const [file, setFile] = useState(null);
    const [reqId, setReqId] = useState('');
    const [load, setLoad] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        global.setTitle('超分辨率');
    }, [file]);

    const judgeSize = async (file) => {
        return await new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
                const { naturalHeight, naturalWidth } = img;
                // 获取原宽高
                resolve(
                    file.size / Math.pow(1024, 2) > 5 ||
                    naturalHeight > 1920 ||
                    naturalWidth > 1920
                );
            }
            //把图片 插插插插 进去
            img.src = URL.createObjectURL(file);
        })
    }

    const handleGetLink = async (uri) => {
        const instance = fetch('https://api.ai.arsrna.cn/release/ArAI-CDNSign', {
            headers: { token },
            method: 'post',
            body: JSON.stringify({ uri })
        }).then(msg => msg.json())
        return (await instance).data;
    }

    const handleSR = (e) => {
        const fileObj = e.file.file.originFileObj;
        judgeSize(fileObj).then(isExceed => {
            if (isExceed) {
                message.warning('图片最大支持5M，1920*1920以下');
                return;
            }
            setLoad(true);
            if (!!file) getBase64(file).then(base64 => setInput(base64));

            const Key = fileUploadKey('enhance');
            cos.putObject({
                ...cosConfig,
                Key, Body: fileObj,
                Headers: {
                    'Pic-Operations':
                        JSON.stringify({
                            "is_pic_info": 1,
                            "rules": [{
                                "fileid": `${Key}_opt.jpg`,
                                "rule": 'ci-process=AISuperResolution'
                            }]
                        })
                },
                onProgress: function (data) {
                    setProgressData(data);
                }
            }, function (err, data) {
                setLoad(false);
                if (err) {
                    Modal.error({ title: '上传时出现错误', content: JSON.stringify(err) });
                    return;
                }
                setReqId(data.RequestId);
                handleGetLink(`${Key}_opt.jpg/bwh`).then(setResult);
            })

            // setFile(fileObj);
            // console.log(fileObj)
        });
    }


    return (<Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Breadcrumb
            items={[{
                title: <a href="/">ArAI</a>,
            }, {
                title: '图像处理',
            }, {
                title: '超分辨率',
            }]}
        />
        <Alert message={<>
            免费内测项目，目前免费使用，商业化后将按标准收费。<br />
            您的用户ID为：{token === void 0 ? <Button type="primary" onClick={handleLogin}>请先点此登录</Button> : JSON.parse(atob(token.split('.')[1])).sub}
        </>} />
        <Row gutter={16}>
            <Col md={12} style={{ width: '100%' }}>
                <Card title="配置">

                    <Spin spinning={load} tip={<div style={{ paddingInline: 10 }}>
                        <Progress percent={progressData.percent * 100} />
                        {(progressData.speed / (1024 ** 2)).toFixed(1)} MB/s
                    </div>}>
                        <Form form={form} layout="vertical"
                            onFinish={handleSR}
                        >
                            <Form.Item label="上传文件" name="file" rules={[{ required: true, message: '请选择文件' }]}>
                                <Dragger
                                    onChange={(e) => setFile(e.file)}
                                    customRequest={console.log}
                                    showUploadList={false}
                                    accept="image/*">
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        点击此处或拖入文件以上传<br />
                                        <small>{file === null ? "暂无文件" : file.name}</small>
                                    </p>
                                </Dragger>
                            </Form.Item>
                            <Form.Item>
                                {token === void '我永远喜欢小艾咪' ?
                                    <Alert message={<>
                                        该功能仅对验证用户有效，请先登录！<br />
                                        <Button type="primary" onClick={handleLogin}>点此登录</Button></>} type="warning" /> :
                                    <Button htmlType="submit" type="primary">提交</Button>
                                }

                            </Form.Item>
                        </Form>
                    </Spin>
                    <Alert message={<>
                        图片最大支持5M以下大小及1920*1920以下分辨率，超出的建议使用<a href="https://www.arsrna.cn/app/esrgan/" target="_blank">本地版</a>
                    </>} />
                </Card>
            </Col>
            <Col md={12} style={{ width: '100%' }}>
                <Card title="结果">
                    <Paragraph>以下内容经机器处理，请注意甄别！</Paragraph>

                    <Row gutter={16}>
                        <Col sm={12}>
                            处理前
                            <AImage
                                fallback={require('../../images/error.png')}
                                src={input} />
                        </Col>
                        <Col sm={12}>
                            处理后
                            <Spin spinning={load}>
                                <AImage
                                    fallback={require('../../images/redirect.png')}
                                    src={result}
                                    preview={{
                                        toolbarRender: (_, {
                                            transform: { scale },
                                            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                                        },) => (
                                            <Space size={12} className="toolbar-wrapper">
                                                <DownloadOutlined onClick={() => window.open(result)} />
                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                <SwapOutlined onClick={onFlipX} />
                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                <RotateRightOutlined onClick={onRotateRight} />
                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                            </Space>
                                        ),
                                    }}
                                />
                            </Spin>
                        </Col>
                    </Row>
                    <p>RequestID: {reqId}</p>

                </Card>
            </Col>
        </Row>
    </Space>)
}

async function getBase64(fileObj) {
    // console.log(fileObj);
    const file = new Blob([fileObj.originFileObj], { type: fileObj.name });
    return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        }
    });
}