import './App.css';
import { Typography } from 'antd';
const { Title } = Typography;

export default function Redirect({ errTitle = "页面禁止访问", errMsg = '前面的区域，以后再来探索吧？' }) {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '60vh',
            flexDirection: 'column'
        }} direction='vertical'>
            <img src={require('./images/redirect.png')} alt="错误" style={{ width: 120, margin: 5 }}></img>
            <Title>{errTitle}</Title>
            {/* <div className='lead'>页面错误，请确认访问地址是否正确；禁止访问。</div> */}
            <div className='lead'>{errMsg}</div>
        </div>
    );
}