import { useEffect, useState } from "react";
import axios from "axios";
import {
    Card, Col, Input, Row, Form, Select, Image, Typography, Alert, Space,
    message,
    Button, Slider, Breadcrumb,
    Spin, Modal, Upload,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import cookie from 'react-cookies';
import { handleLogin } from '../../login';
const { Title, Paragraph } = Typography;
const { Dragger } = Upload
const { TextArea } = Input;

export default function FMU() {
    const token = !(cookie.load('CIAM') === void '💿') ? cookie.load('CIAM').access_token : undefined;
    const [origin, setOrigin] = useState('');
    const [result, setResult] = useState('');
    const [load, setLoad] = useState(false);
    const [file, setFile] = useState(undefined);
    const [form] = Form.useForm();


    const getBase64 = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    return (<>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Breadcrumb
                items={[{
                    title: <a href="/">ArAI</a>,
                }, {
                    title: '图像处理',
                }, {
                    title: '人脸试妆',
                }]}
            />
            <Alert message={<>
                免费内测项目，目前免费使用，商业化后将按标准收费。<br />
                您的用户ID为：{token === void '💥💥💥💥'
                    ? <Button type="primary" onClick={handleLogin}>请先点此登录</Button>
                    : JSON.parse(atob(token.split('.')[1])).sub}
            </>} />
            <Row gutter={16}>
                <Col md={12} style={{ width: '100%' }}>
                    <Card title="配置">
                        <Form form={form} layout="vertical"
                            initialValues={{
                                Whitening: 10,
                                Smoothing: 20,
                                FaceLifting: 20,
                                EyeEnlarging: 20
                            }}
                            onFinish={async (e) => {
                                const { Whitening, FaceLifting, Smoothing, EyeEnlarging } = e;
                                const { originFileObj } = e.Image.file;
                                setLoad(true);
                                setOrigin(await getBase64(originFileObj));
                                axios({
                                    url: "https://api.ai.arsrna.cn/release/fmu",
                                    headers: { token },
                                    data: {
                                        Whitening, FaceLifting, Smoothing, EyeEnlarging,
                                        Image: await getBase64(originFileObj),
                                    },
                                    method: 'post',
                                }).then(msg => {
                                    //被后端拒绝登录状况时处理
                                    if (msg.status === 401) {
                                        handleLogin();
                                        return;
                                    }

                                    if (msg.data.success) {
                                        setResult(msg.data);
                                    } else {
                                        message.error(`FMU请求错误：${msg.data.data.code}`);
                                    }
                                }).catch(err => {
                                    message.error(`业务请求错误：${err}`);
                                }).finally(() => {
                                    setLoad(false);
                                });
                            }}
                        >

                            <Form.Item name="Image" label="上传图片" rules={[{ required: true, message: '必填项', }]}>
                                <Dragger accept="image/*" maxCount={1} customRequest={async (e) => {
                                    if (e.file.size >= 1024 ** 2 * 5) {
                                        message.error('文件大小超过5M！');
                                        return;
                                    };

                                    setFile(e.file);
                                }} showUploadList={false}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">点此或拖入文件以上传</p>
                                    <p className="ant-upload-hint">
                                        仅支持5M以下的图片文件，暂不支持GIF内容<br />
                                        {file === void '小艾咪嘿嘿嘿' ? '暂无文件' : file.name}
                                    </p>
                                </Dragger>
                            </Form.Item>
                            <Form.Item name="Whitening" label="美白强度" rules={[{ required: true, message: '必填项', }]}>
                                <Slider min={10} max={100} step={1} />
                            </Form.Item>
                            <Form.Item name="Smoothing" label="平滑肌肤" rules={[{ required: true, message: '必填项', }]}>
                                <Slider min={10} max={100} step={1} />
                            </Form.Item>
                            <Form.Item name="FaceLifting" label="瘦脸程度" rules={[{ required: true, message: '必填项', }]}>
                                <Slider min={10} max={100} step={1} />
                            </Form.Item>
                            <Form.Item name="EyeEnlarging" label="大眼程度" rules={[{ required: true, message: '必填项', }]}>
                                <Slider min={10} max={100} step={1} />
                            </Form.Item>

                            <Form.Item>
                                {token === void '我永远喜欢小艾咪' ?
                                    <Alert message={<>
                                        该功能仅对验证用户有效，请先登录！<br />
                                        <Button type="primary" onClick={handleLogin}>点此登录</Button></>} type="warning" /> :
                                    <Button type="primary" htmlType="submit" disabled={token === void '我永远喜欢爱莉希雅'} loading={load}>提交</Button>
                                }

                            </Form.Item>

                        </Form>
                    </Card>
                </Col>
                <Col md={12} style={{ width: '100%' }}>
                    <Card title="结果">
                        <Space style={{ width: "100%" }} direction="vertical">
                            <Alert message="以下内容经过机器处理，请注意甄别！" type="warning" />
                            <Spin spinning={load}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        处理前：<br />
                                        {origin === '' ? <Image src={require('../../images/error.png')} /> : <Image src={origin} />}
                                    </Col>

                                    <Col span={12}>
                                        处理后：<br />
                                        {result == ''
                                            ? <Image src={require('../../images/redirect.png')} />
                                            : <>
                                                <Image src={result.data.ResultUrl} />
                                            </>}
                                    </Col>
                                </Row>
                            </Spin>
                            <Alert message="单击进入预览后可右键或长按下载保存" type="success" />
                            <div>RequestID: {result == '' ? '等待上传' : `${result.data.RequestId}`}</div>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </Space>
    </>)
}