import { useEffect, useState } from "react";
import {
    Card, Col, Input, Row, Form, Select, Image, Typography, Alert, Space,
    message, Breadcrumb,
    Button, Collapse, Progress, Tabs,
    Spin, Modal, Upload,
} from "antd";
import { InboxOutlined, WarningOutlined, QuestionCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import cookie from 'react-cookies';
import { handleLogin, parseIdToken } from '../../login';
import { COSInit, cosConfig, fileRandomKey, COSDownload } from "../../cos";
const { Title, Paragraph } = Typography;
const { Dragger } = Upload

const token = !(cookie.load('CIAM') === void '💿') ? cookie.load('CIAM').access_token : undefined;
const cos = COSInit(token);

export default function FMU() {
    const [result, setResult] = useState([]);
    const [resultImg, setResultImg] = useState('');
    const [load, setLoad] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm();
    const { RecognitionResult } = null || result;
    const file = fileList.length === 0 ? undefined : fileList[0].originFileObj;

    useEffect(() => { global.setTitle('图像审核'); }, [])

    const HitFlag = [
        <Title level={3} type="success"><CheckCircleOutlined /> 确认通过</Title>,
        <Title level={3} type="danger"><WarningOutlined /> 确认违规</Title>,
        <Title level={3} type="warning"><QuestionCircleOutlined /> 疑似违规</Title>
    ]
    const textLabel = { "Normal": "正常", "Porn": "色情", "Ads": "广告" };

    const changeFColumn = (i, value) => {
        setFileList(prevFilelist => {
            const updatedFilelist = [...prevFilelist];
            updatedFilelist[i] = { ...updatedFilelist[i], ...value };
            return updatedFilelist;
        });
    }

    const handleUpload = (originFileObj) => {
        setLoad(true);
        const filePath = `/ai/audit/${parseIdToken().payload.sub}/${fileRandomKey()}`;
        cos.uploadFile({
            ...cosConfig,
            Key: filePath,
            Body: originFileObj, // 上传文件对象
            SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
            Headers: {
                'Pic-Operations': JSON.stringify({
                    "is_pic_info": 1,
                    "rules": [{
                        "fileid": `${filePath}_cop`,
                        "rule": "imageMogr2/format/webp/thumbnail/3840x3840"
                    }]
                }
                )
            },
            onProgress: function (data) {
                const { percent } = data;
                // console.log(data);
                changeFColumn(0, { percent: percent * 100 });
            },
            onFileFinish: function (err, data, options) {
                if (err) {
                    Modal.error({
                        title: '上传失败！',
                        content: JSON.stringify(err)
                    });
                    changeFColumn(0, { status: 'error' });
                    return;
                }
                getImageAuditing(`${filePath}_cop`, originFileObj.size >= 1024 * 1024 * 5);
                changeFColumn(0, { status: 'success' });
            },
        });
    }


    function getImageAuditing(Key, LID) {
        cos.request({
            ...cosConfig,
            Method: 'GET',
            Key,  /* 存储桶内的图片文件，必须字段 */
            Query: {
                'ci-process': 'sensitive-content-recognition', /** 固定值，必须 */
                'biz-type': '', /** 审核类型，非必须 */
                'large-image-detect': LID ? '0' : '1', /** 是否需要压缩图片后再审核，非必须 */
                'dataid': Key, /** 自定义图片标识，非必须 */
            },
        }, function (err, data) {
            if (err) {
                message.error(JSON.stringify(err));
                setLoad(false);
                return;
            }
            console.log(data);
            COSGet(Key)
                .then(msg => setResultImg(msg))
                .catch(err => message.error(JSON.stringify(err)));
            setFileList([]);
            setLoad(false);
            setResult(data);
        });
    }

    const ResTab = ({ res }) => (<>
        <Progress type="circle" percent={Math.floor(res.Score)} />
        <div>{HitFlag[parseInt(res.HitFlag)]}</div>
        <Breadcrumb items={[{
            title: res.Category
        }, {
            title: res.Label
        }, {
            title: res.SubLabel
        }]} />
    </>)


    return (<>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Breadcrumb
                items={[{
                    title: <a href="/">ArAI</a>,
                }, {
                    title: '图像识别',
                }, {
                    title: '申鹤',
                }]}
            />
            <Alert message={<>
                当前内容为免费项目，允许免费使用1000张，商业化后将按标准收费。<br />
                {token === void '💥💥💥💥'
                    ? <Button type="primary" onClick={handleLogin}>点此登录后使用</Button>
                    : <>您的用户ID为：{JSON.parse(atob(token.split('.')[1])).sub}</>}
            </>} />
            <Row gutter={16}>
                <Col md={12} style={{ width: '100%' }}>
                    <Card title="配置">
                        <Alert message="以上内容需经过机器处理，请勿上传隐私内容！" type="warning" />
                        <Form form={form} layout="vertical"
                            onFinish={async (e) => {
                                const { originFileObj } = e.Image.file;
                                handleUpload(originFileObj);
                            }}
                        >

                            <Form.Item name="Image" label="上传图片" rules={[{ required: true, message: '必填项', }]}>
                                <Dragger accept="image/*" maxCount={1} customRequest={console.log}
                                    onChange={(e) => {
                                        if (e.file.size >= 1024 ** 2 * 32) {
                                            message.error('文件大小超过32M！');
                                            return;
                                        };
                                        setFileList(e.fileList);
                                    }}
                                    fileList={fileList}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">点此或拖入文件以上传</p>
                                    <p className="ant-upload-hint">
                                        仅支持32M，2.5亿像素以下大小, 暂不支持GIF内容
                                    </p>
                                </Dragger>
                            </Form.Item>

                            <Form.Item>
                                {token === void '我永远喜欢小艾咪' ?
                                    <Alert message={<>
                                        该功能仅对验证用户有效，请先登录！<br />
                                        <Button type="primary" onClick={handleLogin}>点此登录</Button></>} type="warning" /> :
                                    <Button type="primary" htmlType="submit"
                                        disabled={token === void '我永远喜欢爱莉希雅' || fileList.length === 0}
                                        loading={load}>提交</Button>
                                }
                            </Form.Item>

                        </Form>
                    </Card>
                </Col>
                <Col md={12} style={{ width: '100%' }}>
                    <Card title="审核详情">
                        <Space style={{ width: "100%" }} direction="vertical">
                            <Paragraph>RequestID: {result.length === 0 ? '等待上传' : `${result.RequestId}`}</Paragraph>

                            <Spin spinning={load}>
                                {result.length === 0
                                    ? <Image src={require('../../images/redirect.png')} height={200} width={200} />
                                    : <Row gutter={16}>
                                        <Col md={24} lg={12} style={{ paddingBottom: 50, width: '100%' }}>
                                            <Collapse

                                                items={[
                                                    {
                                                        key: 'resultImg',
                                                        label: '查看原图',
                                                        children: <Image src={resultImg} />,
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col md={24} lg={12}><Tabs
                                            tabPosition="left"
                                            items={[{
                                                label: '色情',
                                                key: 'PornInfo',
                                                children: <ResTab res={RecognitionResult.PornInfo} />,
                                            }, {
                                                label: '广告引导',
                                                key: 'AdsInfo',
                                                children: <ResTab res={RecognitionResult.AdsInfo} />,
                                            }, {
                                                label: '涉恐信息',
                                                key: 'TerrorismInfo',
                                                children: <ResTab res={RecognitionResult.TerrorismInfo} />,
                                            }, {
                                                label: '涉政信息',
                                                key: 'PoliticsInfo',
                                                children: <ResTab res={RecognitionResult.PoliticsInfo} />,
                                            }]}
                                        /></Col>
                                    </Row>}
                            </Spin>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </Space>
    </>)
}

async function COSGet(Key) {
    return await new Promise((resolve, reject) => {
        cos.getObjectUrl({
            ...cosConfig,
            Key,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
            Sign: true,
            Protocol: 'https:',
        }, function (err, data) {
            if (err) {
                reject(err);
                return;
            }
            resolve(data.Url);
        });
    });
}