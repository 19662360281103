import axios from 'axios';
import cookie from 'react-cookies';

var tokenAll, idToken, accessToken, userInfo;

export function getUser(contents, hideContent) {
    if (!(GetQueryValue('code') == null)) {
        ArCIAM.getToken(GetQueryValue('code'), localStorage.getItem('codeVerifier'),
            function (msg) {
                cookie.save('CIAM', msg.responseText, { expire: 1, path: '/' })
                console.log(msg);
                window.location.href = funcUrlDel('code').replace('?', '')
            });
    }

    if (cookie.load('CIAM') == null) {
        console.log('未登录')
        // $('#loginButton').show();
        // $('#logoutButton').hide();
    } else {
        if (!(JSON.parse(cookie.load('CIAM')).error == null)) {
            cookie.remove('CIAM')
        }
        tokenAll = JSON.parse(cookie.load('CIAM'));
        //已登录回调
        idToken = tokenAll.id_token;
        accessToken = tokenAll.assess_token;
        userInfo = JSON.parse(atob(idToken.split('.')[1]));
        console.log(userInfo)
        if (userInfo.exp <= (new Date().getTime() / 1000)) {
            cookie.remove('CIAM');
            // getUser();
            console.log('iat异常');
            return;
        }
        //refreshToken();
        // $('#loginButton').hide();
        // $('#logoutButton').show();
        // $('#user').html(userInfo.nickname);
        // $(contents).removeAttr('disabled');
        // $(hideContent).hide();
    }
}

export function refreshToken() {
    return (ArCIAM.refreshToken(tokenAll.refresh_token,
        function (msg) {
            console.log(JSON.parse(msg.responseText));
            if (JSON.parse(msg.responseText).error == null) {
                cookie.save('CIAM', msg.responseText, { expire: 7, path: '/' })
                console.log('设置新的token' + msg.responseText)
            } else {
                //  cookie.remove('CIAM'.{path:'/'})
            }
        }).responseText)
    // return(JSON.parse(localStorage.getItem('CIAM')))
}

function sha256(plain) {
    // returns promise ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
}

function base64urlencode(a) {
    // Convert the ArrayBuffer to string using Uint8 array.
    // btoa takes chars from 0-255 and base64 encodes.
    // Then convert the base64 encoded to base64url encoded.
    // (replace + with -, replace / with _, trim trailing =)
    return btoa(String.fromCharCode.apply(null, new Uint8Array(a)))
        .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

async function pkce_challenge(v) {
    var hashed = await sha256(v);
    var base64encoded = base64urlencode(hashed);
    return base64encoded;
}

function GetQueryValue(queryName) {
    var query = decodeURI(window.location.search.substring(1));
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == queryName) { return pair[1]; }
    }
    return null;
}



var path = 'https://www.arsrna.cn/login',
    redirectURL = `${path}/redirect.html`,
    scope = 'openid',
    client_id = 'MTYxMTdmZmEzNWZmNDA1NTk5NGVhZWMyMDI5ODIxOTQ',
    code_challenge_method = 'S256';


ArCIAM = {
    generateCV() {
        return (Math.random().toString(36).slice(-8));
    },

    redirectPKIE(callbackUrl, code_verifier) {
        pkce_challenge(code_verifier).then(code_challenge => {
            localStorage.setItem('redirect', callbackUrl);
            const url = new URL('https://arsrna.portal.tencentciam.com/oauth2/authorize');
            url.searchParams.append('scope', 'openid');
            url.searchParams.append('client_id', 'MTYxMTdmZmEzNWZmNDA1NTk5NGVhZWMyMDI5ODIxOTQ');
            url.searchParams.append('redirect_uri', redirectURL);
            url.searchParams.append('response_type', 'code');
            url.searchParams.append('state', callbackUrl);
            url.searchParams.append('code_challenge_method', 'S256');
            url.searchParams.append('code_challenge', code_challenge);
            window.location.href = url.toString();
        });
    },

    logout() {
        localStorage.setItem('redirect', window.location.href);
        const logoutURL = (`${path}/logout.html`)
        window.location.href = `https://arsrna.portal.tencentciam.com/logout?client_id=${client_id}&logout_redirect_uri=${logoutURL}#${window.location.href}`;
    },

    async refreshToken(refresh_token) {
        new Promise((resolve, reject) => {
            axios({
                url: 'https://api.arsrna.cn/release/CIAM',
                method: 'post',
                data: {
                    method: 'refreshToken',
                    refresh_token,
                },
            }).then(msg => resolve(msg.data))
                .catch(err => reject(err))
        })
    },

    redirectCall(url) {
        window.location.href = unescape(`${url}?code=${GetQueryValue('code')}`)
    },

    getStatus(token) {
        if (JSON.parse(atob(token.split('.')[1])).exp <= new Date().getTime() / 1000) {
            return false
        } else {
            return true
        }
    },

    handleGetToken() {
        const { code } = GetQueryJson();
        if (code !== void '💿') {
            const { code_verifier } = localStorage;
            const formData = new URLSearchParams();
            formData.append('code', code);
            formData.append('redirect_uri', 'https://www.arsrna.cn/login/redirect.html');
            formData.append('code_verifier', code_verifier);

            axios({
                url: `https://ciam.arsrna.cn/release/oauth2/token`,
                data: formData,
                method: 'post',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }).then(msg => {
                const ciam = msg.data
                console.log(ciam);
                const expires = new Date(new Date().getTime() + 3 * 24 * 3600 * 1000);
                cookie.save('CIAM', JSON.stringify(ciam), { expires, path: '/' });
                funcUrlDel('code');
            })
                .catch(err => {
                    alert('登录失败！' + err);
                    console.log(err)
                });
        }
    }

}

export var ArCIAM;

/**
 * @description 删除url中某个参数,并跳转
 * @param {string} name URL参数
 */
export function funcUrlDel(name) {
    const url = new URL(window.location.href);
    url.searchParams.delete(name);
    window.location.href = url.toString();
}

/**
 * 获得Query对象
 * @returns {Object}
 */
export function GetQueryJson() {
    return Object.fromEntries(new URLSearchParams(window.location.search));
}

/**
 * @description 请求登录并跳转
 */
export function handleLogin() {
    const url = window.location.href;
    const code_verifier = ArCIAM.generateCV();
    localStorage.setItem('code_verifier', code_verifier);
    ArCIAM.redirectPKIE(url, code_verifier);
}

/**
 * @description 获得cooke中CIAM的id_token对象
 * @returns {Object} 
 */
export function parseIdToken() {
    const [header, payload, verify] = cookie.load('CIAM').id_token.split('.');
    return ({
        header: JSON.parse(atob(header)),
        payload: JSON.parse(atob(payload))
    })
}

export function handleLogout() {
    cookie.remove('CIAM', { path: '/' });
    ArCIAM.logout()
}

export const token = !(cookie.load('CIAM') === void '💿') ? cookie.load('CIAM').access_token : undefined;