import {
    Card, Col, Input, Row, Form, Select, Image, Typography, Alert, Space,
    message,
    Button,
    Spin, Modal
} from "antd";
import { useEffect, useState } from "react";
import cookie from 'react-cookies';
import axios from 'axios';
import { handleLogin } from '../../login';
const { Title, Paragraph } = Typography;
const { TextArea } = Input;

export default function StableDiffusion() {
    const token = !(cookie.load('CIAM') === void '💿') ? cookie.load('CIAM').access_token : undefined;
    const [result, setResult] = useState('');
    const [invokeTimes, setInvokeTimes] = useState(0);
    const [combination, setCombination] = useState(`<lora:griseo:1>, griseo, honkai (series), honkai impact 3rd, jehyun, 1girl, arm up, bangs, bare shoulders, bikini, blue bow, blue hair, blue sky, bow, bracelet, breasts, brown headwear, cleavage, cloud, day, frilled bikini, frills, hat, hat bow, horizon, jewelry, long hair, looking at viewer, ocean, outdoors, parted lips, pearl bracelet, purple eyes, sky, small breasts, solo, straw hat, swimsuit, very long hair, water`);
    const [seed, setSeed] = useState('');
    const [load, setLoad] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        window.setTitle('绘画');
        fetch('https://api.ai.arsrna.cn/release/balance/single?type=art-sd-invoke-time', {
            headers: { token },
        }).then(msg => msg.json())
            .then(msg => setInvokeTimes(msg.msg))
            .catch(err => message.error('获取用户额度失败！' + err));
    }, []);

    const handleAjax = (data) => {
        setLoad(true)
        const { prompt, nprompt, resolution, model } = data;
        const [width, height] = JSON.parse(resolution);
        axios({
            url: `https://api.ai.arsrna.cn/release/sd/`,
            headers: { token, 'Content-Type': 'application/json' },
            data: JSON.stringify({
                "denoising_strength": 0,
                "prompt": `${combination},${prompt}`,
                "negative_prompt": nprompt,
                "seed": -1,
                "batch_size": 1,
                "n_iter": 1,
                "steps": 18,
                "cfg_scale": 7,
                "width": width,
                "height": height,
                "restore_faces": false,
                "tiling": false,
                "override_settings": {
                    "sd_model_checkpoint": model
                },
                "script_args": [],
                "sampler_index": "Euler"
            }),
            method: 'post',
        }).then(msg => {
            const { data } = msg;

            if (!data.success) {
                message.error(data.data)
                return;
            }
            const res = JSON.parse(data.data);
            const img = `data:image/jpeg;base64,${res.images[0]}`;
            setResult(img);
            setSeed(JSON.parse(res.info).seed);
            setInvokeTimes(data.balance);

            localStorage.setItem('result', img);
            message.success(`生成成功，用时：${(data.time / 1000).toFixed(2)}s`);
            // console.log(msg.data);

        }).catch(err => {
            Modal.error({
                title: 'Axios 实例请求错误',
                content: err.message,
            });
        }).finally(() => setLoad(false));
    }

    return (<Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Alert message={<>
            充值通道暂未开启，您目前还剩<b> {invokeTimes} </b>次调用，请参照<a href="https://user.arsrna.cn/" target="_blank">用户中心</a>显示额度为准<br />
            您的用户ID为：{token === void 0 ? <Button type="primary" onClick={handleLogin}>请先点此登录</Button> : JSON.parse(atob(token.split('.')[1])).sub}
        </>} />
        <Row gutter={16}>
            <Col md={12} style={{ width: '100%' }}>
                <Card title="配置">
                    <Form form={form} layout="vertical"
                        initialValues={{
                            nprompt: localStorage.nprompt === void '我永远喜欢爱莉希雅' ?
                                'lowres, bad anatomy, bad hands, text, error, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry,malformed hands,worst quality,low quality,ugly,duplicate,trans,trannsexual,out of frame,extra fingers,mutated hands,more than 2 nipples,out of frame,extra limbs,bad anatomy,grossproportions,malformed limbs,missing arms,missinglegs,extra arms,extra legs,mutated hands,fusedfingers,long neck,fingers,extra digit,cropped,bad feet,poorly drawnhands,poorly drawn face,mutation,deformed,blurry,bad anatomy,bad proportions,extra_limbs,disfigured.bad anatomy disfigured,malformed mutated,missing limb,normal quality,too many fingers,long neck,bad finglegs,cropped，bad feet,bad anatomy disfigured,malformed mutated,morbid,mutilated,tranny,missing limb,malformed hands,ugly,pregnant,vore,trannsexual,mutilated,morbid,duplicate,hermaphrodite,extra fingers,fused fingers,too many fingers,mutated hands,long neck,poorly drawn face,mutation,bad proportions,bad anatomy,blurry,deformed,malformed limbs,extra limbs,cloned face,disfigured,extra arms,missing legs,missing arms,gross proportions,extra legs,deformed legs,more than 2 knee,mutated breasts,poorly drawn asymmetric eyes,written words, script,writing, written language,word,text font,english,alphabet,letter,text,Extra ears,poorly drawn hands,missing toes'
                                : localStorage.nprompt,

                            resolution: '[512,768]',
                            model: 'momoko-e.ckpt [18bcc837a2]',
                            combination: `<lora:griseo:1>, griseo, honkai (series), honkai impact 3rd, jehyun, 1girl, arm up, bangs, bare shoulders, bikini, blue bow, blue hair, blue sky, bow, bracelet, breasts, brown headwear, cleavage, cloud, day, frilled bikini, frills, hat, hat bow, horizon, jewelry, long hair, looking at viewer, ocean, outdoors, parted lips, pearl bracelet, purple eyes, sky, small breasts, solo, straw hat, swimsuit, very long hair, water`,
                            prompt: localStorage.prompt === void 'fischl' ? '' : localStorage.prompt
                        }}
                        onFinish={(e) => {
                            const { prompt, nprompt } = e
                            localStorage.setItem('prompt', prompt);
                            localStorage.setItem('nprompt', nprompt);
                            handleAjax(e);
                        }}
                    >

                        <Form.Item label="Prompt组合" name="combination">
                            <Select options={[{
                                label: '菲谢尔 <lora:fischl1-000008:1>',
                                value: 'a girl, {{fischl (genshin impact)}}, blonde hair, green eyes, eyepatch, hair over one eye, two side up,purple bowtie,cowboy shot, Perfect female body,an extremely delicate and beautiful girl,wallpaper,8k_wallpaper,{{{{masterpiece}}}}',
                            }, {
                                label: '格蕾修 <lora:griseo:1>',
                                value: '<lora:griseo:1>, griseo, honkai (series), honkai impact 3rd, redfish, 1girl, ahoge, blue butterfly, blue hair, bug, butterfly, closed mouth, dress, expressionless, hair ornament, hairclip, hat, insect, long hair, looking at viewer, paint, paintbrush, palette (object), ponytail, purple eyes, solo, standing,{{masterpiece}}',
                            }, {
                                label: '格蕾修2 <lora:griseo:1>',
                                value: '<lora:griseo:1>, griseo, honkai (series), honkai impact 3rd, redfish, 1girl, ahoge, blue butterfly, blue hair, bug, butterfly, closed mouth, dress, expressionless, grey background, hair ornament, hairclip, hat, insect, long hair, looking at viewer, paint, paintbrush, palette (object), ponytail, purple eyes, solo, standing',
                            }, {
                                label: '格蕾修3 <lora:griseo:1>',
                                value: '<lora:griseo:1>, griseo, honkai (series), honkai impact 3rd, gou lianlian dogface, bangs, barefoot, blue hair, dress, feet, hair ornament, holding, holding brush, looking at viewer, paint splatter, palette (object), purple eyes, simple background, soles, toes, white background, white dress',
                            }, {
                                label: '爱莉希雅 <lora:char-Honkai-Elysia:1>',
                                value: `(Highest picture quality), (Master's work), (ultra-detailed), (Detailed eye description:1.2),，masterpiece,best quality,beautiful detailed eyes and face, 1girl, white clothes, looking at viewer, solo,pink hair, solo, long hair, flower, tree, water, lily pad, hair flower, dress, hair ornament, outdoors, long sleeves, pond, bangs, white dress, braid, puffy long sleeves, pink eyes, white flower, nature, closed mouth, pink flower, puffy sleeves, lake <lora:char-Honkai-Elysia:0.7>,avatar`,
                            }]} onChange={setCombination} />
                        </Form.Item>

                        <Form.Item label="PositivePrompt" name="prompt">
                            <TextArea
                                rows={5}
                                placeholder={`已内置的Prompt：${combination}`} />
                        </Form.Item>

                        <Form.Item label="NegativePrompt" name="nprompt">
                            <TextArea
                                rows={5}
                                placeholder="负面Prompt" />
                        </Form.Item>

                        <Form.Item label="模型" name="model">
                            <Select options={[{
                                label: 'CounterfeitV2.5',
                                value: 'CounterfeitV25_25.safetensors [a074b8864e]',
                            }, {
                                label: 'momoko-e',
                                value: 'momoko-e.ckpt [18bcc837a2]',
                            }, {
                                label: 'NovelAI v1-5-pruned-emaonly',
                                value: 'v1-5-pruned-emaonly.safetensors [6ce0161689]',
                            },]} />
                        </Form.Item>

                        <Form.Item label="分辨率" name="resolution">
                            <Select options={[{
                                label: '1024*512',
                                value: '[1024,512]',
                            }, {
                                label: '512*512',
                                value: '[512,512]',
                            }, {
                                label: '768*512',
                                value: '[768,512]',
                            }, {
                                label: '512*768',
                                value: '[512,768]',
                            }]} />
                        </Form.Item>

                        <Form.Item>
                            {token === void '我永远喜欢小艾咪' ?
                                <Alert message={<>
                                    该功能仅对验证用户有效，请先登录！<br />
                                    <Button type="primary" onClick={handleLogin}>点此登录</Button></>} type="warning" /> :
                                <Button type="primary" htmlType="submit"
                                    disabled={token === void '我永远喜欢爱莉希雅' || invokeTimes <= 0} loading={load}>
                                    生成（剩余{invokeTimes}次）
                                </Button>
                            }

                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col md={12} style={{ width: '100%' }}>
                <Card title="结果">
                    <Paragraph>以下内容为机器合成，请注意甄别！</Paragraph>
                    <Spin spinning={load}>
                        <Image src={result}
                            fallback={localStorage.result === void '💿💿💿💿' ? require('./images/index.jpg') : localStorage.result} />
                        <p>Seed: {seed}</p>
                        <Button onClick={() => {
                            localStorage.removeItem('result');
                            message.success('清除完成');
                        }} danger>清除历史记录</Button>
                    </Spin>
                </Card>
            </Col>
        </Row>
    </Space>)
}