import COS from 'cos-js-sdk-v5';
import { uuid } from './plug';
import { Modal } from 'antd';
import { parseIdToken } from './login';

export const fileRandomKey = () => (`ArAI_${new Date().getTime()}_${uuid()}.jpg`);
export const fileUploadKey = (name) => (`/ai/${name}/${parseIdToken().payload.sub}/ArAI_${new Date().getTime()}_${uuid()}.jpg`);
export function COSInit(token) {
    return new COS({
        // getAuthorization 必选参数
        getAuthorization: function (options, callback) {
            fetch('https://api.ai.arsrna.cn/release/ArAI-coskey',
                {
                    headers: { token }
                }).then(msg => msg.json())
                .then(data => callback({
                    TmpSecretId: data.credentials.tmpSecretId,
                    TmpSecretKey: data.credentials.tmpSecretKey,
                    SecurityToken: data.credentials.sessionToken,
                    StartTime: data.startTime,
                    ExpiredTime: data.expiredTime,
                }))
                .catch(err => Modal.error({
                    title: 'STS取值失败！',
                    content: '请尝试退出登录后重新登录。' + err
                }));
        }
    });
}

export var cosConfig = {
    Bucket: 'temporary-1257609559',
    Region: 'ap-guangzhou',
}
