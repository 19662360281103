import {
    Card, Statistic, Spin, Row, Col, Tabs, Modal, Button,
    Form, Cascader, Flex, message
} from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { handleLogin, token } from "../login";
import Error from "../error";
import { QRCodeSVG } from 'qrcode.react';

export function Buy() {
    // const url = "http://127.0.0.1:9000"
    // window.setTitle('购买');
    const [price, setPrice] = useState(0);
    const [load, setLoad] = useState(false);
    const [project, setProject] = useState(null);

    const getPrice = (instance) => {
        setLoad(true);
        axios.get('https://api.ai.arsrna.cn/release/price', {
            params: { instance }
        })
            .then(msg => {
                const { success, price, err, instance } = msg.data
                if (success) {
                    setPrice(price);
                }
                else {
                    message.error('无法获取价格' + err);
                }
            })
            .catch(e => message.error('无法获取价格' + e))
            .finally(() => setLoad(false));
    }

    const Alipay = ({ project, price }) => {
        const div = useRef(null);
        const [code, setCode] = useState('');
        return (<><Button disabled={price === 0} type="primary" onClick={() => {
            axios({
                url: `http://127.0.0.1:9000/pay`,
                method: 'post',
                data: { project },
                headers: { token }
            })
                .then(msg => {
                    console.log(msg.data);
                    // window.open(msg.data.msg);
                    setCode(msg.data.msg);
                })
                .catch(err => message.error('无法发起请求' + err));
        }
        }>点击支付</Button>
            <Spin spinning={code === ''}>
                <iframe src={code} />
            </Spin>
            <div ref={div} />
        </>)
    }

    const options = [{
        value: 'img',
        label: '图像处理与分析',
        children: [{
            value: 'audit',
            label: '图像审核',
            children: [{
                value: 500,
                label: '500次调用'
            }, {
                value: 1000,
                label: '1000次调用'
            }]
        }, {
            value: 'enhance',
            label: '图像超分辨率',
            children: [{
                value: 1000,
                label: '1000次调用'
            }]
        }]
    }, {
        value: 'art',
        label: 'AI艺术生成',
        children: [{
            value: 'sd-invoke-time',
            label: '图像生成次数',
            children: [{
                value: 20,
                label: '20张',
            }, {
                value: 100,
                label: '100张',
            }, {
                value: 500,
                label: '500张',
            }],
        },
        ],
    }];

    return (<>
        <Card title="支付">
            <Spin spinning={load}>
                <Statistic title="应付金额" prefix="￥" value={price} />
                <Tabs
                    type="card"
                    items={[{
                        label: `支付宝`,
                        key: 'alipay',
                        children: <Alipay price={price} project={project} />,
                    }]}
                />
            </Spin>
        </Card>

        <Card title="购买信息">
            <Cascader.Panel
                options={options}
                onChange={(e) => {
                    setProject(e);
                    getPrice(e.join('-'));
                }}
                style={{ minHeight: 300, minWidth: '100%', fontSize: '110%' }} />

        </Card>

    </>);
}

export default function BuyPage() {
    return (<>
        {token === void '我永远喜欢菲谢尔' ? <Error title="请先登录" desc={<center>
            本页内容仅登录用户可见。<br /><Button type="primary" onClick={handleLogin}>点此登录</Button>
        </center>} /> : <Buy />}

    </>)
}

/*
行业：人工智能，信息技术
服务对象：个人
售卖服务：处理类API，提供人工智能技术处理图像，音视频等多媒体技术。
单价区间：0.5~99
需要账号密码访问功能，仅供测试用账号：
账号：WeChatTest
密码：WeChat@12345
 */