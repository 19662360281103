import { Space, Card, Button, Image, Avatar, Typography, Anchor, Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faFaceSmile, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
const { Meta } = Card;
const { Title, Paragraph } = Typography;

export default function Home() {
    const artRes = [{
        icon: faImage,
        title: '图片艺术创作Demo',
        description: '使用Serverless GPU服务，算力弹性占用，节省资源开销，模型永久存储，即开即用。Lora模型为4张Tesla训练15万steps图片而成。',
        link: '/art/sd/'
    }];

    const imgRes = [{
        icon: faFaceSmile,
        title: '人脸试妆 美颜',
        description: '可用于自拍照片等人像美化场景，上传照片即可一键智能实现美白、磨皮、瘦脸、大眼、美型，支持自定义调整参数，帮助提升个人形象。',
        link: '/img/fmu/'
    }, {
        icon: faMagnifyingGlass,
        title: '图像审核',
        description: '通过深度学习技术，识别可能令人反感、不安全或不适宜的违规图片内容，可识别多种违规场景，包括：低俗、违法违规、色情、广告等场景。',
        link: '/img/shenhe/'
    }, {
        icon: faImage,
        title: '图像超分辨率',
        description: '使用SRGAN处理图片，优化插画线条，还原画质。',
        link: '/img/enhance/'
    }];

    return (
        <>
            <Space direction='vertical' style={{ width: '100%' }} size="large">
                <div>
                    <div style={{ zIndex: 1, position: 'absolute', padding: 50, color: 'white' }}>
                        <div className='title' style={{ letterSpacing: 3 }}>ArSrNa AI中心</div>
                        <span className='lead'>使用AI提高效率，事半功倍</span>
                    </div>
                    <img src={require('./images/index.jpg')} className='hdpic' />
                </div>

                <section>
                    <div className='anchor'>
                        <Anchor
                            direction="horizontal"
                            items={[{
                                key: '艺术创作',
                                href: '#art',
                                title: '艺术创作',
                            }, {
                                key: '图像',
                                href: '#img',
                                title: '图像',
                            },]}
                        />
                    </div>

                    <div id='art'>
                        <Title level={2}>艺术创作</Title>
                        <Row gutter={16}>

                            {artRes.map(res => {
                                const { icon, title, description, link } = res
                                return (<Col md={12} lg={8}> <Card actions={[<a href={link} target='_blank'>点击进入</a>]} >
                                    <Meta
                                        title={<><FontAwesomeIcon icon={icon} style={{ paddingRight: 5 }} />
                                            {title}</>}
                                        description={description}
                                    />
                                </Card></Col>)
                            })}

                        </Row>
                    </div>


                    <div id='art'>
                        <Title level={2}>图像</Title>
                        <Row gutter={16}>

                            {imgRes.map(res => {
                                const { icon, title, description, link } = res
                                return (<Col md={12} lg={8}> <Card actions={[<a href={link} target='_blank'>点击进入</a>]} >
                                    <Meta
                                        title={<><FontAwesomeIcon icon={icon} style={{ paddingRight: 5 }} />
                                            {title}</>}
                                        description={description}
                                    />
                                </Card></Col>)
                            })}

                        </Row>
                    </div>


                </section>
            </Space>
        </>
    )
}